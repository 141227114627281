import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ResTokenModel } from "src/app/models/ResToken";
import datajson from "../../../assets/config.json";
import { AppConfig } from "src/app/services/app.config";

@Injectable()
export class EndpointProvider {
  tokenApiGateway = new BehaviorSubject("NA");

  //private readonly url = 'http://localhost:49520/api';
  //private readonly url = 'http://192.168.33.12:8110/api';
  //private readonly url = datajson['url'];
  //private readonly url = environment.url;
  private url;
  private scope;
  private grantType;
  private content;
  private authorizationCode;
  private urlGetToken;
  private xApiK;

  private readonly consultaCliente: string = "/ConsultaCliente";
  private readonly urlDetalleReclam: string = "/ING_DetalleReclamo";
  private readonly consulta: string = "/CON_Reclamos";
  private readonly productos: string = "/ConsultaGeneral/Productos";
  private readonly productosCliente: string = "/ProductosClienteT";
  private readonly motivos: string = "/ConsultaGeneral/Motivos?";
  private readonly servicios: string = "/ConsultaGeneral/Servicios?";
  private readonly cuentasTC: string = "/consulta-cuentastc";
  private readonly tarjetaCredito: string = "/consulta-tarjeta-credito";
  private readonly tarjetaDebito: string = "/consulta-tarjeta-debito";

  private readonly provincias: string = "/ConsultaGeneral/Provincias";
  private readonly parametrosq01: string = "/ConsultaGeneral/ParametrosQ01";
  private readonly estadosTC: string = "/ConsultaGeneral/EstadosTC";

  private readonly cantones: string = "/ConsultaGeneral/Cantones";
  private readonly parametros: string = "/ConsultaGeneral/ParametrosServicio";
  private readonly generos: string = "/ConsultaGeneral/Generos";
  private readonly clienteNuevo: string = "/consulta-cliente-nuevo";
  private readonly transaccionesCompensadasTC: string =
    "/consulta-transacciones-tc-compensadas";
  private readonly transaccionesCompensadasTD: string =
    "/consulta-transacciones-compensadas-td";
  private readonly camposAdicionales: string =
    "/ConsultaGeneral/CamposAdicionales?";
  private readonly archivos: string = "/ConsultaGeneral/Archivos";
  private readonly ingresoReclamo: string = "/ING_Reclamos";
  private readonly encuesta: string = "/Encuestas/ING_Encuesta";
  private readonly guardaArchivo: string = "/GuardaArchivo";
  private readonly generaOtp: string = "/GeneraOtp?";
  private readonly validaOtp: string = "/ValidaOtp";
  private readonly ciudades: string = "/ConsultarCiudadesOficina";
  private readonly agencias: string = "/ConsultarOficinasPorCiudad?";
  private readonly encuestaForm: string = "/Encuestas/CON_Encuesta?";
  private readonly validarCliente: string = "/ValidarCliente";

  constructor(private http: HttpClient, public uri: AppConfig) {
    this.load();
  }

  public async load() {
    console.log("load");
    try {
      const envResponse = await this.http.get("assets/config.json").toPromise();
      this.url = envResponse["url"];
      this.scope = envResponse["scope"];
      this.grantType = envResponse["grantType"];
      this.content = envResponse["content"];
      this.authorizationCode = envResponse["authorizationCode"];
      this.urlGetToken = envResponse["urlGetToken"];
      this.xApiK = envResponse["xApiK"];

      console.log("this.url", this.url);
    } catch (error) {
      console.error("Error loading config:", error);
    }
  }

  private get urlIngresoReclamo() {
    return this.url + this.ingresoReclamo;
  }
  private get urlConsultaCliente() {
    return this.url + this.consultaCliente;
  }
  private get urlDetalleReclamo() {
    return this.url + this.urlDetalleReclam;
  }

  private get urlConsulta() {
    return this.url + this.consulta;
  }
  private get urlProductos() {
    return this.url + this.productos;
  }
  private get urlProductosCliente() {
    return this.url + this.productosCliente;
  }
  private get urlMotivos() {
    return this.url + this.motivos;
  }
  private get urlServicios() {
    return this.url + this.servicios;
  }

  private get urlCuentasTC() {
    return this.url + this.cuentasTC;
  }
  private get urlTarjetaCredito() {
    return this.url + this.tarjetaCredito;
  }
  private get urlTarjetaDebito() {
    return this.url + this.tarjetaDebito;
  }

  private get urlProvincias() {
    return this.url + this.provincias;
  }

  private get urlParameterosQ01() {
    return this.url + this.parametrosq01;
  }

  private get urlEstados() {
    return this.url + this.estadosTC;
  }

  private get urlCantones() {
    return this.url + this.cantones;
  }
  private get urlParameteros() {
    return this.url + this.parametros;
  }
  private get urlGeneros() {
    return this.url + this.generos;
  }

  private get urlClienteNuevo() {
    return this.url + this.clienteNuevo;
  }
  private get urlTransaccionesCompensadasTC() {
    return this.url + this.transaccionesCompensadasTC;
  }
  private get urlTransaccionesCompensadasTD() {
    return this.url + this.transaccionesCompensadasTD;
  }

  private get urlCamposAdicionales() {
    return this.url + this.camposAdicionales;
  }
  private get urlEncuesta() {
    return this.url + this.encuesta;
  }
  private get urlGuardaArchivo() {
    return this.url + this.guardaArchivo;
  }
  private get urlGeneraOtp() {
    return this.url + this.generaOtp;
  }
  private get urlValidaOtp() {
    return this.url + this.validaOtp;
  }
  private get urlCiudades() {
    return this.url + this.ciudades;
  }
  private get urlAgencias() {
    return this.url + this.agencias;
  }
  private get urlEncuestaForm() {
    return this.url + this.encuestaForm;
  }
  private get urlArchivos() {
    return this.url + this.archivos;
  }
  private get urlValidarCliente() {
    return this.url + this.validarCliente;
  }

  //SET TOKEN
  async getTokenApiGateway(): Promise<Observable<ResTokenModel>> {
    await this.load();
    const params = new HttpParams({
      fromObject: {
        grant_type: this.grantType,
        scope: this.scope,
      },
    });

    console.log("params", params);

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": this.content,
        Authorization: this.authorizationCode,
      }),
    };

    console.log("httpOptions", httpOptions);

    console.log("url no quemada", this.urlGetToken);
    return this.http.post<any>(this.urlGetToken, params, httpOptions);
  }

  setToken(token: string) {
    this.tokenApiGateway.next(token);
  }

  // @ts-ignore
  get token(): string {
    this.tokenApiGateway.getValue();
  }

  getHeaders() {
    // @ts-ignore
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: this.tokenApiGateway.getValue(),
        "x-api-key": this.xApiK,
      }),
    };
  }

  // httpConsultaCliente LISTO
  httpConsultaCliente<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlConsultaCliente}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpDetalleReclamo<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlDetalleReclamo}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  // httpConsulta
  httpConsulta<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlConsulta}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpProductos<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlProductos}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders()); //validar si va vacio
  }

  httpProductosCliente<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlProductosCliente}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpMotivos<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlMotivos}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpServicios<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlServicios}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpCuentasTC<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlCuentasTC}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpTarjetaCredito<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlTarjetaCredito}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }
  httpTarjetaDebito<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlTarjetaDebito}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpProvincias<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlProvincias}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpParametrosQ01<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlParameterosQ01}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpEstados<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlEstados}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpCantones<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlCantones}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }
  httpParametros<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlParameteros}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }
  httpGeneros<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlGeneros}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpClienteNuevo<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlClienteNuevo}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpTransaccionesCompensadasTC<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlTransaccionesCompensadasTC}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }
  httpTransaccionesCompensadasTD<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlTransaccionesCompensadasTD}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpCamposAdicionales<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlCamposAdicionales}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpIngresoReclamo<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlIngresoReclamo}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpEncuesta<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlEncuesta}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpGuardaArchivo<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlGuardaArchivo}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpGenerarOTP<T>(params: any): Observable<T> {
    const endpointUrl = `${this.urlGeneraOtp}`;
    return this.http.post<T>(endpointUrl + params, "", this.getHeaders());
  }

  httpValidarOTP<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlValidaOtp}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpCiudades<T>(): Observable<T> {
    const endpointUrl = `${this.urlCiudades}`;
    return this.http.post<T>(endpointUrl, "", this.getHeaders()); //validar si va vacio
  }

  httpAgencias<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlAgencias}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpEncuestaForm<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlEncuestaForm}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders()); // validar si va vacio
  }

  httpArchivos<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlArchivos}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }

  httpValidarCliente<T>(body: any): Observable<T> {
    const endpointUrl = `${this.urlValidarCliente}`;
    return this.http.post<T>(endpointUrl, body, this.getHeaders());
  }
}
