import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfig {

    public appConfig: string = null;
    readonly url = this.appConfig;
    
    constructor(
        private http: HttpClient
    ) {
    }

    public getConfig() {
        return this.appConfig;
    }

    public load() {
        this.http.get('assets/config.json').subscribe((envResponse) => {
            this.appConfig = envResponse['url'];
        });
    }
}
