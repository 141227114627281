import { Injectable } from "@angular/core";
import { EndpointProvider } from "../endpoint/endpoint";

@Injectable()
export class ApplicationProvider {
  constructor(private coreEndpoint: EndpointProvider) {}

  httpConsultaCliente(body: any) {
    return this.coreEndpoint.httpConsultaCliente(body);
  }

  httpDetalleReclamo(data: any) {
    return this.coreEndpoint.httpDetalleReclamo(data);
  }

  httpConsulta(body: any) {
    return this.coreEndpoint.httpConsulta(body);
  }

  httpProductos(body: any) {
    return this.coreEndpoint.httpProductos(body);
  }

  httpProductosCliente(params: any) {
    return this.coreEndpoint.httpProductosCliente(params);
  }

  httpMotivos(body: any) {
    return this.coreEndpoint.httpMotivos(body);
  }

  httpServicios(body: any) {
    return this.coreEndpoint.httpServicios(body);
  }

  httpCamposAdicionales(body: any) {
    return this.coreEndpoint.httpCamposAdicionales(body);
  }

  httpCuentasTC(body: any) {
    return this.coreEndpoint.httpCuentasTC(body);
  }

  httpTarjetaCredito(body: any) {
    return this.coreEndpoint.httpTarjetaCredito(body);
  }

  httpTarjetaDebito(body: any) {
    return this.coreEndpoint.httpTarjetaDebito(body);
  }

  httpProvincias(body: any) {
    return this.coreEndpoint.httpProvincias(body);
  }

  httpParametrosQ01(body: any) {
    return this.coreEndpoint.httpParametrosQ01(body);
  }

  httpEstados(body: any) {
    return this.coreEndpoint.httpEstados(body);
  }

  httpCantones(body: any) {
    return this.coreEndpoint.httpCantones(body);
  }

  httpParametros(body: any) {
    return this.coreEndpoint.httpParametros(body);
  }

  httpGeneros(body: any) {
    return this.coreEndpoint.httpGeneros(body);
  }

  httpClienteNuevo(body: any) {
    return this.coreEndpoint.httpClienteNuevo(body);
  }

  httpTransaccionesCompensadasTC(body: any) {
    return this.coreEndpoint.httpTransaccionesCompensadasTC(body);
  }

  httpTransaccionesCompensadasTD(body: any) {
    return this.coreEndpoint.httpTransaccionesCompensadasTD(body);
  }

  httpIngresoReclamo(body: any) {
    return this.coreEndpoint.httpIngresoReclamo(body);
  }

  httpEncuesta(body: any) {
    return this.coreEndpoint.httpEncuesta(body);
  }

  httpGuardaArchivo(body: any) {
    return this.coreEndpoint.httpGuardaArchivo(body);
  }

  httpGenerarOTP(params: any) {
    return this.coreEndpoint.httpGenerarOTP(params);
  }

  httpValidarOTP(body: any) {
    return this.coreEndpoint.httpValidarOTP(body);
  }

  httpCiudades() {
    return this.coreEndpoint.httpCiudades();
  }

  httpAgencias(body: any) {
    return this.coreEndpoint.httpAgencias(body);
  }

  httpEncuestaForm(body: any) {
    return this.coreEndpoint.httpEncuestaForm(body);
  }

  httpArchivos(body: any) {
    return this.coreEndpoint.httpArchivos(body);
  }

  httpValidarCliente(body: any) {
    return this.coreEndpoint.httpValidarCliente(body);
  }
}
