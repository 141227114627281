import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  isContact = false;
  constructor() { }

  ngOnInit() {
    const params = this.obtenerParametros(window.location);
    if(params["origen"] === "call_Center") {
      this.isContact = true;
    }
   }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
  }

  obtenerParametros(url) {
    const params = {};
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.hash.substring(21);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

}
