import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogAlertComponent } from '../shared/modals/dialog-alert/dialog-alert.component';
import { MatDialog } from '@angular/material';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  loaderToShow: any;

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    ;

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // event return all info about http request
          //console.log('INFO INTERCEPTOR', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('ERROR CATCH INTERCEPTOR', error);
        this.spinner.hide();
        if (error.status !== 400) {
          this.mostrarAlerta();
        }
        return throwError(error);
      }));
  }

  mostrarAlerta() {
    this.dialog.open(DialogAlertComponent, {
      data: {
        name: 'Banco Bolivariano',
        content: 'Error de conexión con el servidor.',
        buttons: false,
        btnAceptar: false,
        btnCancelar: true
      }
    });
  }
}
