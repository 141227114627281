import { HttpParams, HttpClient } from "@angular/common/http";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from "ngx-spinner";
import { TablaConsulta } from "src/app/models/consulta";
import {
  ApplicationProvider,
  EndpointProvider,
} from "src/app/providers/providers";
import { Router } from "@angular/router";
import { ReqConReclamo } from "../../models/ReqConReclamo";
import { DialogAlertComponent } from "src/app/shared/modals/dialog-alert/dialog-alert.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-consultas",
  templateUrl: "./consultas.component.html",
  styleUrls: ["./consultas.component.scss"],
})
export class ConsultasComponent implements OnInit, AfterViewInit {
  tableItems: TablaConsulta[] = [];
  formConsulta: FormGroup;
  flagOpBusqueda: boolean;
  displayedColumns: string[] = [
    "codReclamo",
    "fechaIngreso",
    "producto",
    "servicio",
    "motivo",
    "monto",
    "estadoReclamo",
    "encuesta",
  ];
  dataSource = new MatTableDataSource<TablaConsulta>(this.tableItems);
  mostrarEliminarBusqueda: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  buscar: string;
  muestraListaMovil: boolean;
  muestraListaWeb: boolean;
  width: number;
  isContact = false;
  dataCliente: any;
  reqCReclamo: ReqConReclamo;
  dataConsulta: any;
  identificacion: string;
  nombre: any;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private coreService: ApplicationProvider,
    private http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private endPoint: EndpointProvider
  ) {}

  ngAfterViewInit() {
    this.width = screen.width;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    /*Formulario ATC Recepcion de Parametros Por Url Ini */
    this.identificacion = "";
    const params = this.obtenerParametros(window.location);
    if (params["identificacion"] !== undefined) {
      this.isContact = true;
      this.eliminaParametrosUrl();
      this.identificacion = params["identificacion"];
    } else {
      this.dataCliente = JSON.parse(sessionStorage.dataCliente);
      this.nombre = this.dataCliente["nombreCliente"].toLowerCase();
    }
    this.consultar();
    this.muestraListaMovil = false;
    this.muestraListaWeb = true;
  }
  /* Formulario ATC Recepcion de Parametros Por Url Ini */
  eliminaParametrosUrl() {
    this.router.navigate([], {
      queryParams: {
        identificacion: null,
        origen: null,
      },
      queryParamsHandling: "merge",
    });
  }

  obtenerParametros(url) {
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.hash.substring(11);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
  /* Formulario ATC Recepcion de Parametros Por Url Ini */

  async irEncuesta(uri) {
    const params = this.obtenerParametros(uri);
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    let url =
      "/encuesta?id=" +
      params["id"] +
      "&idTipoPregunta=" +
      params["idTipoPregunta"];
    this.router.navigateByUrl(url);
  }

  async reqConReclamo() {
    const data: any = {
      CodReclamo: "",
      Identificacion: "",
      token: {
        TokenId: "",
      },
    };
    this.reqCReclamo = data;
    this.reqCReclamo.CodReclamo = "";
    this.reqCReclamo.Identificacion =
      this.identificacion === ""
        ? this.dataCliente.identificacionCliente
        : this.identificacion;
  }

  async consultar() {
    this.tableItems = [];
    await this.reqConReclamo();
    this.spinner.show();
    const en: any = await (
      await this.endPoint.getTokenApiGateway()
    ).toPromise();
    this.endPoint.setToken(en.token_type + " " + en.access_token);
    this.reqCReclamo.token.TokenId = en.access_token;
    this.coreService.httpConsulta(this.reqCReclamo).subscribe(
      (data) => {
        this.dataConsulta = data;
        this.spinner.hide();
        for (const iterator of this.dataConsulta) {
          if (iterator.estadoReclamoDesc.toUpperCase() !== "ELIMINADO") {
            if (iterator.estadoReclamoDesc.toUpperCase() !== "VENCIDO") {
              if (iterator.desOrigen.toUpperCase() !== "ORGANISMO DE CONTROL") {
                if (iterator.codReclamo !== "") {
                  const date = iterator.fechaIngreso.split("T");
                  const tableObject = {
                    codReclamo: iterator.codReclamo,
                    producto: iterator.descripcionProducto,
                    servicio: iterator.descripcioServicio,
                    motivo: iterator.descripcionMotivo,
                    fechaIngreso: date[0],
                    estadoReclamo: iterator.estadoReclamoDesc,
                    monto: iterator.monto,
                    idReclamo: iterator.idReclamo,
                    linkEncuesta: iterator.LinkEncuesta,
                    encuesta: iterator.encuesta,
                  };
                  this.tableItems.push(tableObject);
                }
              }
            }
          }
        }
        this.tableItems.sort((a, b) => {
          return <any>new Date(b.fechaIngreso) - <any>new Date(a.fechaIngreso);
        });
        this.dataSource.data = this.tableItems;
        this.spinner.hide();
        if (screen.width <= 411) {
          this.muestraListaMovil = true;
        } else {
          this.muestraListaWeb = false;
        }
      },
      (e) => {
        if (e.error) {
          this.spinner.hide();
          this.alertaPSM("No se encontraron reclamos asociados");
        }
      }
    );
  }

  alertaPSM(msg: string) {
    this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
  }
}
