import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sideBarOpen = false;
  jsonConfig: any;

  constructor(private http: HttpClient) { }

  async ngOnInit() {
    this.jsonConfig = await this.http.get('assets/config.json').toPromise();
   }


  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  menuCerrado() {
    this.sideBarOpen = !this.sideBarOpen;
  }

}
