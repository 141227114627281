import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import datajson from '../assets/config.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent{
  title = 'app';
  dataConfig: any = datajson;
  userActivity;

  userInactive: Subject<any> = new Subject();
  constructor(private router: Router) {
    this.setTimeout();
    this.userInactive.subscribe(() => console.log('user has been inactive for 5min'));
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined)
      let origen = sessionStorage.origen;
      let url = "/inicio?origen=" + origen;
      //sessionStorage.origen = null;
      this.router.navigateByUrl(url);
    }, this.dataConfig['timeSession']);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
