export class RespuestaRequest {

    IdReclamo: number;
    IdPregunta: number;
    Respuestas: TipoRespuestaRequest;

    constructor(IdReclamo: number,
        IdPregunta: number,
        Respuestas: TipoRespuestaRequest) {
        this.IdReclamo = IdReclamo
        this.IdPregunta = IdPregunta
        this.Respuestas = Respuestas
    }
}

export class TipoRespuestaRequest {
    IdRespuesta: number;
    RespuestaNum: number;
    RespuestaTxt: string;

}