import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ApplicationProvider,
  EndpointProvider,
} from "src/app/providers/providers";
import { NgxSpinnerService } from "ngx-spinner";
import { DialogAlertComponent } from "src/app/shared/modals/dialog-alert/dialog-alert.component";
import { MatDialog } from "@angular/material";
import { Preguntas } from "../../models/Preguntas";
import { RespuestaRequest } from "../../models/RespuestaRequest";
import { Respuesta } from "../../models/Respuesta";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import datajson from "../../../assets/config.json";

@Component({
  selector: "app-encuesta",
  templateUrl: "./encuesta.component.html",
  styleUrls: ["./encuesta.component.scss"],
})
export class EncuestaComponent implements OnInit {
  title = "Encuesta";
  preguntas: Preguntas[];

  FormEncuesta: FormGroup;
  SeleccionRespuesta: RespuestaRequest[] = [];
  id: any;
  idTipoPregunta: any;
  regex: RegExp;
  dataConfig: any = datajson;
  filtro: any;

  constructor(
    private coreService: ApplicationProvider,
    public dialog: MatDialog,
    @Inject(DOCUMENT) document: any,
    private spinner: NgxSpinnerService,
    private router: Router,
    private endPoint: EndpointProvider
  ) {}

  async ngOnInit() {
    this.filtro = this.dataConfig["filtro"];
    this.regex = new RegExp("[" + this.filtro + "]", "g");
    const params = this.obtenerParametros(window.location);
    //const urlParams = new URLSearchParams(params);
    this.id = params["id"];
    this.idTipoPregunta = params["idTipoPregunta"];
    const data: any = {
      IdReclamo: "",
      IdTipoPregunta: "",
      token: {
        TokenId: "",
      },
    };
    data.IdReclamo = this.id;
    data.IdTipoPregunta = this.idTipoPregunta;

    setTimeout(async () => {
      const en: any = await (
        await this.endPoint.getTokenApiGateway()
      ).toPromise();
      this.endPoint.setToken(en.token_type + " " + en.access_token);
      data.token.TokenId = en.access_token;
      this.coreService.httpEncuestaForm(data).subscribe((data: any) => {
        this.preguntas = data;
      });
    }, 500);
  }

  obtenerParametros(url) {
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.hash.substring(11);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  async EnviarEncuesta(): Promise<void> {
    if (
      this.preguntas.filter((data: Preguntas) => data.estado == false).length ==
      0
    ) {
      this.spinner.show();
      let band = true;
      let cont = 0;
      for (var i = 0; i < this.preguntas.length; i++) {
        if (
          this.preguntas[i].Respuestas[0].IdTipoRespuesta == 94 ||
          this.preguntas[i].Respuestas[0].IdTipoRespuesta == 96
        ) {
          var valor = this.SeleccionRespuesta[cont];
          if (valor === undefined) {
            this.alerta("Faltan preguntas por responder");
            band = false;
            this.spinner.hide();
            break;
          }
          cont = cont + 1;
        }
      }
      if (band) {
        const en: any = await (
          await this.endPoint.getTokenApiGateway()
        ).toPromise();
        this.endPoint.setToken(en.token_type + " " + en.access_token);
        this.coreService
          .httpEncuesta(this.SeleccionRespuesta)
          .subscribe((data: any) => {
            this.spinner.hide();
            this.ingresado(data.Mensaje);
          });
      }
    } else {
      this.alerta(
        "Por favor, seleccione una respuesta a cada pregunta de Encuesta."
      );
    }
  }

  GuardarRespuesta(
    event: any,
    respuesta: Respuesta,
    pregunta: Preguntas
  ): void {
    var Seleccion = event.value;
    let idSeleccion = 0;
    if (Seleccion) {
      this.SeleccionRespuesta = this.SeleccionRespuesta.filter(
        (data: RespuestaRequest) => data.IdPregunta != respuesta.IdPregunta
      );

      if (pregunta["Seleccion1"] == Seleccion) {
        idSeleccion = 1;
      } else if (pregunta["Seleccion2"] == Seleccion) {
        idSeleccion = 2;
      } else if (pregunta["Seleccion3"] == Seleccion) {
        idSeleccion = 3;
      } else {
        idSeleccion = 4;
      }

      //this.preguntas.Respuestas.forEach((data: Preguntas) => data.Respuestas. == Number(Seleccion))
      const data = {
        IdRespuesta: idSeleccion,
        RespuestaNum: 0,
        RespuestaTxt: "",
      };
      this.SeleccionRespuesta.push(
        new RespuestaRequest(Number(this.id), respuesta.IdPregunta, data)
      );
    }
  }

  valueStar(event: any, pregunta: Preguntas, respuesta: Respuesta) {
    var number = event.target.value;
    if (number !== "") {
      this.SeleccionRespuesta = this.SeleccionRespuesta.filter(
        (data: RespuestaRequest) => data.IdPregunta != respuesta.IdPregunta
      );
      const data = {
        IdRespuesta: respuesta.IdRespuesta,
        RespuestaNum: Number(number),
        RespuestaTxt: "",
      };
      this.SeleccionRespuesta.push(
        new RespuestaRequest(Number(this.id), respuesta.IdPregunta, data)
      );
    }
  }

  valueTextArea(event: any, pregunta: Preguntas, respuesta: Respuesta) {
    var text = event.target.value.replace(this.regex, "");
    if (text !== "") {
      this.SeleccionRespuesta = this.SeleccionRespuesta.filter(
        (data: RespuestaRequest) => data.IdPregunta != respuesta.IdPregunta
      );
      const data = {
        IdRespuesta: respuesta.IdRespuesta,
        RespuestaNum: 0,
        RespuestaTxt: text,
      };
      this.SeleccionRespuesta.push(
        new RespuestaRequest(Number(this.id), respuesta.IdPregunta, data)
      );
    }
  }
  alerta(msg: string) {
    this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
  }

  ingresado(msg: string) {
    const dialogRef = this.dialog.open(DialogAlertComponent, {
      data: {
        name: "Banco Bolivariano",
        content: msg,
        buttons: false,
        btnAceptar: false,
        btnCancelar: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl("/consultas");
        /*  if (sessionStorage.origen === 'defensor') {
           window.location.href = this.jsonConfig['urlSitio'] + '/#/inicio?origen=defensor';
         } else {
           window.location.href = this.jsonConfig['urlSitio'] + '/#/inicio?origen=cliente';
         } */
      }
    });
  }
}
