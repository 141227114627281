import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ApplicationProvider, EndpointProvider } from "./providers/providers";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { InicioComponent } from "./inicio/inicio.component";
import { FormularioReclamoComponent } from "./modules/formulario-reclamo/formulario-reclamo.component";
import { EncuestaComponent } from "./modules/encuesta/encuesta.component";
import { ConsultasComponent } from "./modules/consultas/consultas.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpConfigInterceptor } from "./services/httpConfig.interceptor";
import { getSpanishPaginatorIntl } from "./mat-component-translate/spanish-paginator-intl";
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  MatCardModule,
  MatIconModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatTableModule,
  MatChipsModule,
  MatButtonModule,
  MatTooltipModule,
  MatDialogModule,
  MatListModule,
  MatRadioModule,
  MatDividerModule,
  MatSidenavModule,
  MatCheckboxModule,
} from "@angular/material";
import { APP_INITIALIZER } from "@angular/core";
import { AppConfig } from "./services/app.config";
import { DefaultComponent } from "./layouts/default/default.component";
import { AuthGuard } from "./guards/auth.guard";
import { DialogAlertComponent } from "./shared/modals/dialog-alert/dialog-alert.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "./shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxCurrencyModule } from "ngx-currency";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "$",
  suffix: "",
  thousands: ".",
  nullable: false,
};

@NgModule({
  entryComponents: [DialogAlertComponent],
  declarations: [
    AppComponent,
    HomeComponent,
    InicioComponent,
    FormularioReclamoComponent,
    ConsultasComponent,
    EncuestaComponent,
    DefaultComponent,
    NavMenuComponent,
  ],
  imports: [
    NgxSpinnerModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    MatDividerModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    SharedModule,
    MatSidenavModule,
    FlexLayoutModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    RouterModule.forRoot([
      {
        path: "",
        redirectTo: "inicio",
        pathMatch: "full",
      },
      {
        path: "",
        component: DefaultComponent,
        children: [
          {
            path: "formulario-reclamo",
            component: FormularioReclamoComponent,
            //canActivate: [AuthGuard]
          },
          {
            path: "consultas",
            component: ConsultasComponent,
            //canActivate: [AuthGuard]
          },
          {
            path: "encuesta",
            component: EncuestaComponent,
            //canActivate: [AuthGuard]
          },
        ],
      },
      {
        path: "inicio",
        component: InicioComponent,
      },
    ]),
    BrowserAnimationsModule,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    ApplicationProvider,
    EndpointProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
