import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PdkMaker {
  public datapdf: any;
  constructor() {}

  async generarPdf(data: any) {
    const docDefinition: any = {
      content: [
        {
          text: "FORMULARIO GRATUITO DE RECLAMOS PARA LOS CONSUMIDORES FINANCIEROS Y/O BENEFICIARIOS",
          style: "header",
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text:
                    "N° Reclamo " +
                    data.codReclamo +
                    "\n(Este número registra la entidad)",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "FECHA: " + data.fecha,
                  alignment: "right",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "DATOS DE LA ENTIDAD",
                  style: "subtitle",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "CANAL",
                  alignment: "right",
                },
                {
                  border: [false, false, false, false],
                  text: "SUCURSAL",
                  alignment: "right",
                },
              ],
              [
                {
                  text: data.entidad,
                  alignment: "left",
                },
                {
                  text: data.canal,
                  alignment: "right",
                },
                {
                  text: data.sucursal,
                  alignment: "right",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "NOMBRE DEL EJECUTIVO DE CUENTA",
                  style: "subtitle",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.nombreEjecutivo,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "IDENTIFICACIÓN DEL USUARIO Y DOMICILIO EXACTO PARA NOTIFICACIONES",
                  style: "subtitle",
                  alignment: "left",
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  text: "PERSONA NATURAL",
                  style: "subtitle",
                  alignment: "left",
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  text: "NOMBRE COMPLETO",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.usuarioNombres === "" ? " " : data.usuarioNombres,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "TIPO DE DOCUMENTO",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "NÚMERO DE DOCUMENTO",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "TELEFONO",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "CELULAR",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.tipoDocumento === "" ? " " : data.tipoDocumento,
                  alignment: "left",
                },
                {
                  text:
                    /*  data.identificacion === ""
                      ? " "
                      : this.maskedPhoneNumber(data.identificacion), */
                    data.identificacion === "" ? " " : data.identificacion,
                  alignment: "left",
                },
                {
                  text: data.telefono1 === "" ? " " : data.telefono1,
                  alignment: "left",
                },
                {
                  text: data.celular === "" ? " " : data.celular,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "DIRECCIÓN",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.direccion === "" ? " " : data.direccion,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "CIUDAD-CANTON",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "PROVINCIA",
                  alignment: "left",
                },
                {
                  border: [false, false, false, false],
                  text: "DIRECCIÓN ELECTRÓNICO",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.ciudad === "" ? " " : data.ciudad,
                  alignment: "left",
                },
                {
                  text: data.provincia === "" ? " " : data.provincia,
                  alignment: "left",
                },
                {
                  text:
                    //data.correo === "" ? " " : this.maskedEmail(data.correo),
                    data.correo === "" ? " " : data.correo,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "PERSONA JURIDICA",
                  style: "subtitle",
                  alignment: "left",
                },
              ],
              [
                {
                  border: [false, false, false, false],
                  text: "RUC",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.ruc === "" ? " " : data.ruc,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "RAZÓN SOCIAL",
                  alignment: "left",
                },
              ],
              [
                {
                  text: data.razonSocial === "" ? " " : data.razonSocial,
                  alignment: "left",
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "DETALLES DE LA CONSULTA, QUEJA O RECLAMO",
                  style: "subtitle",
                },
                {
                  border: [false, false, false, false],
                  text: "N° CUENTA/N° DE TARJETA/N° OPERACIÓN",
                  style: "subtitle",
                },
                { text: this.maskedNumberCuenta(data.codOperacion) },
                { border: [false, false, false, false], text: "" },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "*",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "PRODUCTOS",
                  style: "subtitle",
                },
                {
                  border: [false, false, false, false],
                  text: "SERVICIO",
                  style: "subtitle",
                },
                {
                  border: [false, false, false, false],
                  text: "TIPO RECLAMO",
                  style: "subtitle",
                },
                {
                  border: [false, false, false, false],
                  text: "MONTO DEL RECLAMO",
                  style: "subtitle",
                },
              ],
              [
                {
                  table: {
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          text: data.descProducto,
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                },
                {
                  table: {
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          text: data.descServicio,
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                },
                {
                  table: {
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          text: data.descReclamo,
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                },
                {
                  table: {
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          text: "US $" + data.monto,
                          alignment: "left",
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "100%",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "DESCRIPCION DE LOS HECHOS MATERIA DE ESTE RECLAMO (Si requiere más espacio puede adjuntar carta explicativa)",
                  style: "subtitle",
                },
              ],
              [data.descHechos],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: "100%",
            // keepWithHeaderRows: 1,
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: "PETICION EN CONCRETO QUE DIRIGE A LA ENTIDAD",
                  alignment: "left",
                  style: "subtitle",
                },
              ],
              [
                {
                  text: data.peticion,
                },
              ],
            ],
          },
        },
      ],
      styles: {
        header: {
          bold: true,
          alignment: "center",
          margin: [0, 0, 0, 10],
          fontSize: 9,
        },
        tableExample: {
          margin: [0, 0, 0, 5],
          fontSize: 8,
        },
        subtitle: {
          bold: true,
          color: "black",
        },
        firma: {
          margin: [0, 50, 0, 0],
          alignment: "center",
        },
      },
    };
    if (data.isDefensor == false) {
      if (data.listaCamposAdi !== undefined) {
        //if (data.listaCamposAdi.Campos !== undefined) {
        docDefinition.content.push({
          border: [false, false, false, false],
          text: "INFORMACIÓN ADICIONAL",
          style: "subtitle",
          fontSize: 8,
        });
        const table = {
          style: "tableExample",
          color: "#444",
          table: {
            widths: ["50%", "50%"],
            body: [],
          },
        };
        data.listaCamposAdi.forEach((list) => {
          const a = [];
          a.push(list.Descripcion);
          a.push(list.Valor);
          table.table.body.push(a);
        });
        docDefinition.content.push(table);
        //}
      }

      const table2 = {
        style: "tableExample",
        color: "#444",
        table: {
          widths: "*",
          body: [],
        },
      };
      const table3 = {
        style: "tableExample",
        color: "#444",
        table: {
          widths: "*",
          body: [],
        },
      };
      table2.table.body.push(
        [
          {
            border: [false, false, false, false],
            text: "DOCUMENTOS QUE SE ADJUNTAN",
            style: "subtitle",
          },
          { border: [false, false, false, false], text: "" },
        ],
        [
          {
            table: {
              body: [
                [
                  {
                    border: [false, false, false, false],
                    text: "OTROS DOCUMENTOS",
                  },
                  { text: data.docCed ? "X" : " " },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: "COPIA DE LA TRANSACCION OBJETO DEL RECLAMO",
                  },
                  { text: data.docTrx ? "X" : " " },
                ],
              ],
            },
          },
          {
            table: {
              body: [
                [
                  {
                    border: [false, false, false, false],
                    text: "CARTA EXPLICATIVA SI FUERA EL CASO",
                  },
                  { text: data.docCarta ? "X" : " " },
                ],
                [
                  { border: [false, false, false, false], text: "DENUNCIA" },
                  { text: data.docDenuncia ? "X" : " " },
                ],
              ],
            },
          },
        ]
      );
      docDefinition.content.push(table2);
      table3.table.body.push(
        [
          {
            border: [false, false, false, false],
            text: "AUTORIZACION Y DECLARACION DEL CONSUMIDOR FINANCIERO",
            style: "subtitle",
          },
        ],
        [
          {
            border: [false, false, false, false],
            text:
              "Las notificaciones que se generen del presente reclamo solicito se remitan a la dirección de correo electrónico arriba indicada.\n" +
              "Declaro que el reclamo no ha sido conocido o está por resolverse en sede judicial, arbitral o administrativa por las autoridades u organismos competentes.",
          },
        ],
        [
          {
            border: [false, false, false, false],
            text: "Firma del Cliente",
            style: "firma",
          },
        ]
      );
      docDefinition.content.push(table3);
    } else if (data.isDefensor == true) {
      const table2 = {
        style: "tableExample",
        color: "#444",
        table: {
          widths: "*",
          body: [],
        },
      };
      const table3 = {
        style: "tableExample",
        color: "#444",
        table: {
          widths: "*",
          body: [],
        },
      };
      table2.table.body.push(
        [
          {
            border: [false, false, false, false],
            text: "DOCUMENTOS QUE SE ADJUNTAN",
            style: "subtitle",
          },
          { border: [false, false, false, false], text: "" },
        ],
        [
          {
            table: {
              body: [
                [
                  {
                    border: [false, false, false, false],
                    text: "OTROS DOCUMENTOS",
                  },
                  { text: data.docCed ? "X" : " " },
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: "COPIA DE LA TRANSACCION OBJETO DEL RECLAMO",
                  },
                  { text: data.docTrx ? "X" : " " },
                ],
              ],
            },
          },
          {
            table: {
              body: [
                [
                  {
                    border: [false, false, false, false],
                    text: "CARTA EXPLICATIVA SI FUERA EL CASO",
                  },
                  { text: data.docCarta ? "X" : " " },
                ],
                [
                  { border: [false, false, false, false], text: "DENUNCIA" },
                  { text: data.docDenuncia ? "X" : " " },
                ],
              ],
            },
          },
        ]
      );
      docDefinition.content.push(table2);
      table3.table.body.push(
        [
          {
            border: [false, false, false, false],
            text: "AUTORIZACION Y DECLARACION DEL CONSUMIDOR FINANCIERO",
            style: "subtitle",
          },
        ],
        [
          {
            border: [false, false, false, false],
            text:
              "Autorizo al Defensor del Cliente a solicitar a la entidad financiera la información necesaria para" +
              "la tramitación del reclamo presentado.\n" +
              "Declaro que el reclamo no ha sido conocido o está por resolverse en sede judicial, arbitral" +
              "o administrativa por las autoridades u organismos competentes.",
          },
        ],
        [
          {
            border: [false, false, false, false],
            text: "Firma del Cliente",
            style: "firma",
          },
        ]
      );
      docDefinition.content.push(table3);
    }
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.getBase64((data) => {
      this.datapdf = data;
    });
  }

  maskedPhoneNumber(phoneNumber: string): string {
    if (phoneNumber === undefined) return "";
    const visibleDigits = 2;
    const maskedPart = "X".repeat(
      phoneNumber.length - (visibleDigits + visibleDigits)
    );
    const visiblePartEnd = phoneNumber.slice(-visibleDigits);
    const visiblePartIni = phoneNumber.slice(0, visibleDigits);
    return visiblePartIni + maskedPart + visiblePartEnd;
  }

  maskedNumberCuenta(cuenta: string): string {
    if (cuenta === undefined) return "";

    const visibleDigits = 3;

    if (cuenta.length <= visibleDigits * 2) {
      // Si la cuenta tiene longitud igual o menor que el doble de los dígitos visibles,
      // mostrar todos los dígitos.
      return cuenta;
    } else {
      const visiblePartStart = cuenta.slice(0, visibleDigits);
      const maskedPart = "X".repeat(cuenta.length - visibleDigits * 2);
      const visiblePartEnd = cuenta.slice(-visibleDigits);

      return visiblePartStart + maskedPart + visiblePartEnd;
    }
  }

  maskedEmail(email: string): string {
    if (email === undefined) return "";
    const atIndex = email.indexOf("@");
    if (atIndex > 0) {
      const visiblePartInt =
        email[0] + "X".repeat(8) + email.slice(atIndex - 1, atIndex);
      const visiblePartEnd = email.slice(atIndex);
      return visiblePartInt + visiblePartEnd;
    } else {
      return email;
    }
  }
}
