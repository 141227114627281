import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  items = [
    {
      nombre: 'Ingresar Reclamo',
      link: '/formulario-reclamo',
      icon: 'assignment',
      origen: ''
    },
    {
      nombre: 'Consultar Reclamo',
      link: '/consultas',
      icon: 'search',
      origen: ''
    },
    {
      nombre: 'Salir',
      link: '/inicio',
      icon: 'exit_to_app',
      origen: sessionStorage.origen
    }
  ];

  @Output()
  eventoCerrar = new EventEmitter<string>();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  cerrarMenu(item) {
    if (item == 'Salir') {
      sessionStorage.tipoPersona = null;
      sessionStorage.dataCliente = null;
    }
    this.eventoCerrar.emit();
  }


  eliminarSession() {
    let origen = sessionStorage.origen;
    let url = "/inicio?origen=" + origen;
    sessionStorage.origen = null;
    this.router.navigateByUrl(url);
  }

  refrescar(link: any) {
    let currentUrl = this.router.url;
    if (currentUrl !== link) {
      this.router.navigateByUrl(link);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }


  }
}
